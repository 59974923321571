import { StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {},
  hr: { height: 27 },
  header: {
    alignItems: "center",
    height: 56,
    width: 243,
    justifyContent: "center",
    marginHorizontal: "auto",
    marginTop: -27,
  },
  title: {
    lineHeight: 18,
    fontSize: 18,
  },
  titleMask: {
    ...StyleSheet.absoluteFillObject,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default styles;
