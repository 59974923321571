import React from "react";
import { SText } from "base-components";
import {
  Pressable,
  PressableProps,
  View,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import { scale } from "utils/StyleSheet";

interface Props extends PressableProps {
  width?: number;
  height?: number;
  title?: string;
  style?: ViewStyle;
  disable?: Boolean;
  titleStyle?: TextStyle;
}

const ButtonWithBg: React.FC<Props> = (props) => {
  const {
    width = 90,
    height = 30,
    title,
    style,
    disable,
    titleStyle = {},
    ...restProps
  } = props;

  return (
    <Pressable
      key={`${title}${disable}`}
      style={{
        width: scale(width),
        height: scale(height),
        ...style,
      }}
      disabled={Boolean(disable)}
      {...restProps}
    >
      <View style={disable ? styles.contentMaskDisabled : styles.contentMask}>
        <SText
          style={{
            ...styles.title,
            ...titleStyle,
            ...(disable ? styles.titleDisabled : {}),
          }}
        >
          {title}
        </SText>
      </View>
    </Pressable>
  );
};

export default ButtonWithBg;
