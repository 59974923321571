import COLORS from "utils/CommonColors";
import { scale, StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.violet1000,
    borderColor: COLORS.boxBorder,
    borderWidth: 1,
    padding: 12,
    display: "flex",
    flexDirection: "row",
    borderRadius: 12,
    alignItems: "center",
    marginBottom: 12,
    boxShadow: `inset 0px 0px ${scale(10)}px rgba(248, 115, 251, 0.5)`,
  },
  coinLogo: {
    marginRight: 12,
  },
  giftContent: {
    width: 152,
    marginRight: 12,
  },
  giftDescription: {
    color: COLORS.lightPurple600,
    lineHeight: 20,
  },
  giftTitle: {
    lineHeight: 20,
  },
  transperant50: {
    opacity: 0.5,
  },
});
export default styles;
