import React from "react";
import { SText, SvgIcon } from "base-components";
import {
  Pressable,
  PressableProps,
  View,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import { scale } from "utils/StyleSheet";

interface Props extends PressableProps {
  width?: number;
  height?: number;
  title?: string;
  style?: ViewStyle;
  disable?: Boolean;
  icon?: string;
  titleStyle?: TextStyle;
}

const SButton: React.FC<Props> = (props) => {
  const {
    width = 90,
    height = 30,
    title,
    style,
    disable,
    icon,
    titleStyle = {},
    ...restProps
  } = props;

  return (
    <Pressable
      key={`${title}${disable}`}
      style={{
        width: scale(width),
        height: scale(height),
        ...style,
      }}
      disabled={Boolean(disable)}
      {...restProps}
    >
      <SvgIcon
        name={icon || (disable ? "btn_inactive" : "btn_active")}
        width={width}
        height={height}
      />
      <View style={styles.contentMask}>
        <SText style={{ ...styles.title, ...titleStyle }}>{title}</SText>
      </View>
    </Pressable>
  );
};

export default SButton;
