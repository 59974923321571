export const DeviceAction = {
  REMITANO_BROWSER_READY: "REMITANO_BROWSER_READY",
  REMITANO_LITE_BROWSER_READY: "REMITANO_LITE_BROWSER_READY",
  UPDATE_DEVICE: "UPDATE_DEVICE",
  SHOW_CUSTOM_ALERT: "SHOW_CUSTOM_ALERT",
  SET_CUSTOM_MODAL_READY: "SET_CUSTOM_MODAL_READY",
  SHOW_CONFIRM_MODAL: "SHOW_CONFIRM_MODAL"
};

export const UserAction = {
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  AUTHEN_FAILED: "AUTHEN_FAILED",
  UPDATE_SPIN_TURN: "UPDATE_SPIN_TURN",
  UPDATE_TOKEN_BALANCE: "UPDATE_TOKEN_BALANCE",
};

export const RankingAction = {
  UPDATE_RANK_LIST: "UPDATE_RANK_LIST",
};

export const CampaignAction = {
  UPDATE_CAMPAIGN: "UPDATE_CAMPAIGN",
  UPDATE_CAMPAIGN_TIME: "UPDATE_CAMPAIGN_TIME",
};
