import COLORS from "utils/CommonColors";
import { scale, StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    minHeight: 100,
    marginHorizontal: 16,
    marginTop: 16,
    backgroundColor: COLORS.violet1000,
    borderColor: COLORS.boxBorder,
    borderWidth: 1,
    borderRadius: 12,
    boxShadow: `inset 0px 0px ${scale(10)}px rgba(248, 115, 251, 0.5)`,
  },
});

export default styles;
