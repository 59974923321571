import React from "react";
import { SText, SvgIcon } from "base-components";
import { View, Image } from "react-native";
import styles from "./styles";
import { Ranking } from "stores/types/ranking";
import { Moment } from "moment";

interface Props {
  rankInfo: Ranking | undefined;
  index?: number;
  currentDate: Moment;
}

const UserRankItem: React.FC<Props> = ({ rankInfo, index, currentDate }) => {
  if (!rankInfo) return <View style={styles.flex1} />;

  const getMedal = () => {
    switch (rankInfo.rating) {
      case 1:
      case 2:
      case 3:
        return <SvgIcon name={`medal${rankInfo.rating}`} size={24} />;
      default:
        return (
          <SText
            size={16}
            style={{
              ...styles.rating,
              ...(rankInfo.isLocal && styles.currentRating),
            }}
          >
            {rankInfo.rating === -1 ? "-" : rankInfo.rating}
          </SText>
        );
    }
  };

  return (
    <View style={[styles.container, rankInfo.isLocal && styles.currentUser, index === 0 && styles.boderTop]}>
      <View style={styles.content}>
        {getMedal()}
        <SText style={styles.name} size={16} bold>
          {rankInfo.name}
          {rankInfo.isLocal && " (you)"}
        </SText>
      </View>
      <View style={styles.content}>
        <SText style={styles.coinNumber} size={16} bold>
          {rankInfo.balance}
        </SText>
        <Image source={require("assets/spin-wheel/big-gem.png")} style={styles.gem} />
      </View>
    </View>
  );
};

export default UserRankItem;
