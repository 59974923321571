import React from "react";
import { View } from "react-native";
import { NavBar, TabBarAnimated } from "base-components";
import { sendEventLog } from "utils/EventLogger";
import RewardBoard, { RewardBoardRef } from "./components/RewardBoard";
import Locale from "locale";
import styles from "./styles";
import { useNavigate } from "react-router-dom";
import SW_SCREENS from "../info";

const TABS = [Locale.translate("valid_gift"), Locale.translate("gift_history")];

const MyRewardsScreen: React.FC = () => {
  const rewardBoardRef = React.useRef<RewardBoardRef>(null);
  const navigate = useNavigate();

  const handleSelect = (index: number) => {
    rewardBoardRef?.current?.setShowHistory(TABS[index] === Locale.translate("gift_history"));
  };

  React.useEffect(() => {
    sendEventLog("view_luckydraw_myrewards");
  }, []);

  return (
    <View style={styles.container}>
      <NavBar title={Locale.translate("my_reward")} onBack={() => navigate(SW_SCREENS.HomeScreen.path)} />
      <View style={styles.flex1}>
        <View style={[styles.pH24, styles.mt20]}>
          <TabBarAnimated onSelect={handleSelect} tabs={TABS} />
        </View>
        <View style={styles.flex1}>
          <RewardBoard ref={rewardBoardRef} />
        </View>
      </View>
    </View>
  );
};

export default MyRewardsScreen;
