import axios from "axios";
import { browserName, mobileModel } from "react-device-detect";
import { isArray, isObject } from "lodash";
import { Platform } from "react-native";
import Locale from "locale";
import CustomError from "./CustomError";
import DeviceInfo from "./DeviceInfo";
import RemitanoBrowser from "./RemitanoBrowser";
import Store from "stores";
import { UserAction } from "stores/actions";
import TimeHelper from "./TimeHelper";

export const REMITANO_DOMAIN = process.env.REACT_APP_REMITANO_DOMAIN;
export const REMITANO_MINI_GAME_DOMAIN = `${REMITANO_DOMAIN}/lucky-draw`;
export const COINLYMPIA_DOMAIN = process.env.REACT_APP_COINLYMPIA_DOMAIN || "";
export const IMOTA_DOMAIN = "https://www.imota.io/";
export const COIN_SAVI_DOMAIN = "https://coinsavi.com/";
export const LOYALTY_MISSION_DOMAIN = "https://loyalty.remitano.com/badge-collection";
export const IMOTA_FANPAGE = "https://twitter.com/Imota_app";
export const COIN_SAVI_FANPAGE = "https://www.facebook.com/CoinSavi.Official";
export const swapPath = "/swap";
export const quickBuySellPath = "/quick-buy-sell";
export const zaP2pPath = "/sell";
export const walletsPath = "/dashboard/wallets";
export const rewardCenterPath = "/reward-center";
export const swapPathType = "root.swapForm";
export const kycPath = "/kyc";
export const loginPath = "/home/login";
export const gameHubPath = "/gamehub";

export const getMobileInfoApiHeaders = () => ({
  "X-Mobile-Platform": Platform.OS,
  "X-Mobile-identifier": DeviceInfo.UNIQUE_ID,
  "X-App-Type": "betamano",
  "X-Device-Uuid": DeviceInfo.UNIQUE_ID,
  Os: Platform.OS,
  Browser: browserName,
  "User-Agent": `native.remitano-${Platform.OS}-app`,
});

const execptionHandle = async (error: any) => {
  if (error?.status === 401) {
    Store.dispatch({
      type: UserAction.AUTHEN_FAILED,
    });
  }
};

const withErrorHandler = (generator: any) => async (...args: any) => {
  try {
    return await generator(...args);
  } catch (error: any) {
    TimeHelper.runOnceIn("API_EXCEPTION_HANDLER", 1000)(() => {
      execptionHandle(error);
    });
    throw error;
  }
};

const queryParams = (params: any = {}) => {
  const arrayParams: any = [];

  Object.keys(params)
    .forEach((key) => {
      const value: any = params[key];

      if (isArray(value)) {
        value.forEach((subValue) => {
          const encodedKey = encodeURIComponent(`${key}[]`);
          const encodedValue = encodeURIComponent(subValue);

          arrayParams.push(`${encodedKey}=${encodedValue}`);
        });
      } else if (isObject(value)) {
        Object.keys(value).forEach((subKey: any) => {
          // @ts-ignore
          const subValue = value[subKey];
          const encodedKey = encodeURIComponent(`${key}[${subKey}]`);
          const encodedValue = encodeURIComponent(subValue);

          arrayParams.push(`${encodedKey}=${encodedValue}`);
        });
      } else {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);

        arrayParams.push(`${encodedKey}=${encodedValue}`);
      }
    });

  return arrayParams.join("&");
};

export const generateHeader = (additionalHeaders = {}) => {
  const mobileHeaders = getMobileInfoApiHeaders();
  const Lang = Locale.currLocale;

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // Coin: getCurrentWalletPageCoinInfo().coin || Coin,
    Lang,
    // Country,
    // Mode: window.REACT_RAILS_ENV.mode,
    // Wholesale: window.REACT_RAILS_ENV.wholesale,
    "Mobile-Model": mobileModel,
    ...mobileHeaders,
    ...additionalHeaders,
  };
};

export const request = async (path: string, options: any) => {
  const requestHeader = generateHeader(options.headers);
  options.headers = requestHeader;
    // @ts-ignore
  const isRemiBrowser = !!window.RemitanoBrowser || !!window.RemitanoLiteBrowser;
  try {
    const requester = isRemiBrowser ? RemitanoBrowser.axiosRequest : axios.request;
    const result = await requester({
      url: path,
      ...options,
      withCredentials: true,
    });
    // @ts-ignore
    if (isRemiBrowser && result.error && result.isException) {
      throw result.error;
    }
    return result.data;
  } catch (error: any) {
    const { response } = error || {};
    if (error?.status === 500 || response?.status === 500) {
      reportError(error);
      throw new CustomError(Locale.translate("other_something_went_wrong"), 500);
    }
    if (!response) {
      throw new CustomError(Locale.translate("network_disconnected"), 503);
    }
    const message = response?.data?.error || response.statusText || Locale.translate("unknown_error_occurred");
    throw new CustomError(message, response); // v1
  }
};

const get = (path: string, params = null, headers = {}, opts = null) => {
  let path2 = path;
  if (params) {
    path2
      += (path.indexOf("?") === -1 ? "?" : "&")
      + queryParams(params);
  }

  const options = {
    method: "get",
    headers,
    ...opts || {},
  };

  return request(path2, options);
};

const post = (path: string, params = null, headers = {}) => {
  const options = {
    method: "post",
    data: JSON.stringify(params),
    headers,
  };
  return request(path, options);
};

const upload = (path: string, params = null, headers = {}) => {
  const uploadHeaders = {
    ...headers,
  };
  // @ts-ignore
  delete uploadHeaders["Content-Type"];
  const options = {
    method: "post",
    data: params,
    headers: uploadHeaders,
  };
  return request(path, options);
};

const putRequest = (path: string, params = null, headers = {}) => {
  const options = {
    method: "put",
    data: JSON.stringify(params),
    headers,
  };
  return request(path, options);
};

const deleteRequest = (path: string, params = null, headers = {}) => {
  const options = {
    method: "delete",
    data: JSON.stringify(params),
    headers,
  };

  return request(path, options);
};

const getWithErrorHandler = withErrorHandler(get);
const postWithErrorHandler = withErrorHandler(post);
const uploadWithErrorHandler = withErrorHandler(upload);
const putRequestWithErrorHandler = withErrorHandler(putRequest);
const deleteRequestWithErrorHandler = withErrorHandler(deleteRequest);

const RequestHelper = {
  withErrorHandler,
  get: getWithErrorHandler,
  post: postWithErrorHandler,
  upload: uploadWithErrorHandler,
  putRequest: putRequestWithErrorHandler,
  deleteRequest: deleteRequestWithErrorHandler,
  queryParams,
};

export default RequestHelper;
