import React from "react";
import { SText, SvgIcon } from "base-components";
import { Pressable } from "react-native";
import styles from "./styles";

interface Props {
  title: string;
  icon: string;
  onPress?: any;
}

const ButtonWithIcon: React.FC<Props> = ({ title, icon, onPress }) => {
  return (
    <Pressable onPress={onPress} style={styles.itemCenter}>
      <SvgIcon name={icon} size={52} />
      <SText bold style={styles.buttonTitle}>
        {title}
      </SText>
    </Pressable>
  );
};

export default ButtonWithIcon;
