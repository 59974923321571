const words = {
  kyc_to_continue: "Please kyc lv2 to play game",
  kyc: "KYC",
  my_reward: "My Rewards",
  share_title: "Planet tour, pick your gift with Remitano #MysteryPlanet #cryptogames",
  mission_swap: "Swap from {amount}$",
  mission_quick_buy_sell: "Buy/Sell {amount}$ from coin to VND",
  mission_fiat: "Deposit from {amount}$ into VNDR, NGNR, ZARR wallet",
  mission_login: "Visit game daily",
  mission_share: "Share this game on Facebook",
  mission_renec: "Exchange RENEC",
  mission_p2p: "P2P from",
  mission_liquidity: "",
  mission_lending: "Supply or borrow from {amount}$ (USDT, USDC, BUSD)",
  mission_quiz: "Learn crypto with Coinlympia",
  mission_imota_wallet : "Download & Register Imota Wallet",
  mission_coin_to_zarr : "Sell ${amount} from coins to ZARR",
  mission_collect_zarr_badges : "Collect 11 badges in \"Sport collection\"",
  mission_coin_savi : "Download app & Register CoinSavi",
  mission_social_follow: "Like CoinSavi social media",
  mission_invest: "Create Liquidity",
  mission_loyalty: "Collect Remitano 9th years anniversary badge",
  mission_collect_ng_badges: "Collect 7 Joyful Badges in Loyalty Program",
  mission_login_continuously: "Login to Remitano for 7 consecutive days (in the event time)",
  mission_ng_trade: "Trade (buy/ sell) with total volume in the event time from {amount}",
  spin: "Spin",
  more_spin: "More spin",
  my_bag: "My rewards",
  rank: "Leaderboard",
  you_have: "You have",
  spin_turn: "spin",
  mission_board: "Mission board",
  mystery_planet: "Mystery Planet",
  complete: "Complete",
  completed: "Completed",
  count_left: "{total} left",
  count_left_test: "{total} left {total} test",
  congratulation: "Congratulations!",
  check_reward: "Check rewards",
  share_reward: "Share",
  valid_gift: "Valid gift",
  gift_history: "Gift history",
  receive: "Receive",
  open_wallet: "Open wallet",
  spin_now: "Spin now",
  use_now: "Use now",
  review_answers: "Review answers”",
  share_description: "Share to get 1 more play",
  share_now: "Share now",
  unrank_title: "Not ranking yet",
  unrank_desc: "Be at the top of the leaderboard today!",
  use_gift_success: "You have used the gift successfully",
  error: "Error",
  daily: "Daily",
  weekly: "Weekly",
  play_now: "Play now",
  continue_play: "Continue",
  q_you_have: "You have",
  turn: "turn",
  title_empty_game_passed: "Coinlympia lacks footprint",
  sub_empty_game_passed:
    "A journey of a thousand miles begins with a single step. The summit of Coinlympia awaits",
  how_to_play: "How to play",
  how_to_step_1: "1. Signup/Login Remitano to play",
  how_to_step_2: "2. Click “Play now” and answer 5 random quizzes",
  how_to_step_3: "3. Check the result of choose (x) to skip to the next quiz",
  how_to_step_4: "4. Check your rank at “Leaderboard”",
  how_to_step_5: "5. Review your quizzes at “Review your answers”",
  how_to_step_6: "6. Share with your friends to get 1 more turn per day and challenge them",
  how_to_step_7: "7. This turn is only valid within a day, and can not be cumulative",
  share_to_play: "Share to get more turn",
  ranking_chart: "Leaderboard",
  passed_game: "Passed game",
  question: "Question",
  answer: "Anwser",
  read_more: "Read more",
  sentence: "Sentence",
  result: "Result",
  required_login: "Login to Continue",
  winning_congrat: "Congratulations!",
  correct_answers: "Correct answers",
  you_received2: "You received",
  you_received: "Received",
  points: "points",
  you: "You",
  week: "Week",
  your_rank: "Your rank",
  q_title_share: "COINLYMPIA - CRYPTO DISCOVERY",
  finish_share: "You did this quest today",
  finishing_congrat: "You have completed",
  unknown_error_occurred: "An unknown error occurred",
  network_disconnected: "Internet connection error, please try again",
  other_something_went_wrong: "Something went wrong",
  login: "Login",
  login_to_continue: "Please login to play game",
  out_of_spin_turn: "You have no spin left, complete a mission below!",
  game_rule: "Game Rules",
  hint_1: "OMG! You just won",
  description_1: "Big transactions big rewards!",
  hint_2: "Venus just gifted you",
  description_2: "Trade more to earn more valuable gifts like this!",
  goodluck_hint_1: "Good luck next spin!",
  goodluck_des_1: "Countless gifts are waiting for you!",
  goodluck_hint_2: "Aim for the star",
  goodluck_des_2: "Trade more and you may find precious gifts!",
  goodluck_hint_3: "Whispers from the planets",
  goodluck_des_3: "Complete any Swap mission to receive your SUPER lucky spins!",
  badluck_title_1: "Wow slow down",
  badluck_title_2: "Tip for you",
  badluck_title_3: "I must tell you",
  badluck_des_1: "Swap now or save your spin when luck returns!",
  badluck_des_2: "If you Swap, your luck will be increased up to 18 times!",
  badluck_des_3: "Complete a Swap mission to see you luck increased up to 18 times!",
  warning: "Warning",
  confirm: "Confirm",
  cancel: "Cancel",
  continue_spin: "Continue to spin",
  cancel_spin: "Cancel spin",
  expire: "Expire",
  just_won: "just won",
  version: "Version",
  spin_gift: "Spin turn",
  good_luck_gift: "Good luck next time",
};

export default words;
