import React, { useMemo, useEffect } from "react";
import { Image, View } from "react-native";
import { SText, SvgIcon } from "base-components";
import CacheImageService from "services/CacheImageService";
import COLORS from "utils/CommonColors";
import ProgressBar from "apps/crypto-quiz/components/Progess/Bar";
import { scale } from "utils/StyleSheet";
import Locale from "locale";
import styles from "./styles";
import { GlobalState } from "stores/types/store";
import { useDispatch, useSelector } from "react-redux";
import { CampaignAction } from "stores/actions";
import { checkCampaign } from "../Home/components/utils";
import {
  CURRENT_MARKET,
  END_CAMPAIGN_DATE,
  START_CAMPAIGN_DATE,
  START_ZA_CAMPAIGN_DATE,
  END_ZA_CAMPAIGN_DATE,
  START_VN_CAMPAIGN_DATE,
  END_VN_CAMPAIGN_DATE,
} from "apps/spin-wheel/common";

interface Props {
  onReady(): void;
}

const PRELOAD_IMGS = [
  "board",
  "pointer",
  "star-background",
  "wheel",
  "wheel-imota",
  "wheel-coin-savi",
  "wheel-loyalty",
  "wheel-za",
  "table-header",
  "pepe-stop",
  "pepe-helmet",
];

const checkDate = new Date();

const percent = 100 / 2;
const SplashScreen: React.FC<Props> = (props) => {
  const [progress, setProgress] = React.useState(0);
  const [currentMarket, setCurrentMarket] = React.useState("");
  const { isVnCampaignTime, isZaCampaignTime, isCampaignTime } = useSelector(
    (state: GlobalState) => state.campaign
  );
  const dispatch = useDispatch();
  // api
  const promiseApi = (delay: number) =>
    new Promise((resolve) => setTimeout(resolve, delay));

  const loadData = async () => {
    let newProgress = 0;
    await Promise.all([
      CacheImageService.preloadListImg(PRELOAD_IMGS).then(() => {
        newProgress = newProgress + percent;
        setProgress(newProgress);
      }),
      promiseApi(1000).then(() => {
        newProgress = newProgress + percent;
        setProgress(newProgress);
      }),
      promiseApi(3000),
    ]);
    setTimeout(props.onReady, 500);
  };

  useEffect(() => {
    dispatch({
      type: CampaignAction.UPDATE_CAMPAIGN_TIME,
      isCampaignTime: checkCampaign(
        checkDate,
        START_CAMPAIGN_DATE,
        END_CAMPAIGN_DATE
      ),
      isZaCampaignTime: checkCampaign(
        checkDate,
        START_ZA_CAMPAIGN_DATE,
        END_ZA_CAMPAIGN_DATE
      ),
      isVnCampaignTime: checkCampaign(
        checkDate,
        START_VN_CAMPAIGN_DATE,
        END_VN_CAMPAIGN_DATE
      ),
    });
    loadData();
  }, []);

  useEffect(() => {
    const market = localStorage.getItem(CURRENT_MARKET);
    if (market) setCurrentMarket(market);
  }, []);

  const imageBg = useMemo(() => {
    if (isVnCampaignTime && currentMarket === "vn")
      return require(`assets/spin-wheel/splash-vn-${Locale.currLocale}.png`);
    if (isZaCampaignTime && currentMarket === "za")
      return require("assets/spin-wheel/splash-za.png");

    return isCampaignTime
      ? require(`assets/spin-wheel/splash-loyalty-${Locale.currLocale}.png`)
      : require(`assets/spin-wheel/splash-${Locale.currLocale}.png`);
  }, [currentMarket, isCampaignTime, isVnCampaignTime, isZaCampaignTime]);

  return (
    <View style={styles.container}>
      <Image
        source={imageBg}
        style={
          isCampaignTime || isZaCampaignTime ? styles.imageBg2 : styles.imageBg
        }
      />
      <View style={styles.content}>
        {!isCampaignTime && !isZaCampaignTime && currentMarket !== "za" && (
          <SvgIcon
            name="remitano-logo"
            width={84}
            height={20}
            style={styles.itemCenter}
          />
        )}
        <div />
        <View style={styles.progressBar}>
          <ProgressBar
            rounded
            width={scale(160)}
            progress={progress}
            fillColor={COLORS.primaryA}
          />
          <SText style={styles.appVersion}>
            {Locale.translate("version")}: 0.1.10
          </SText>
        </View>
      </View>
    </View>
  );
};

export default SplashScreen;
