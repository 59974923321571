import { StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#27133A",
  },
  icBack: {
    padding: 10,
    marginLeft: 2,
    paddingRight: 0,
  },
  centerItem: { flexDirection: "row", alignItems: "center" },
  titleContainer: {
    flex: 1,
    paddingRight: 46,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "700",
    fontSize: 16,
    color: "white",
    textTransform: "capitalize",
  },
});

export default styles;
