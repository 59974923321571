import { scale, StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {},
  title: {
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    fontWeight: "900",
    color: "white",
    fontSize: 18,
    lineHeight: 18,
    textShadowColor: "#B96302",
    textShadowOffset: {
      width: scale(1),
      height: scale(3),
    },
    position: "absolute",
    alignSelf: "center",
  },
});
export default styles;
