import COLORS from "utils/CommonColors";
import { scale, StyleSheet } from "utils/StyleSheet";
const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 16,
  },
  historyConatainer: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 16,
  },
  historyContentContainer: {
    flex: 1,
    backgroundColor: COLORS.violet1000,
    borderColor: COLORS.boxBorder,
    borderWidth: 1,
    borderRadius: 12,
    boxShadow: `inset 0px 0px ${scale(10)}px rgba(248, 115, 251, 0.5)`,
  }
});

export default styles;
