import React from "react";
import { View, Image } from "react-native";
import { useSelector } from "react-redux";
import Locale from "locale";

import { SText } from "base-components";
import COLORS from "utils/CommonColors";
import CacheImageService from "services/CacheImageService";
import { GlobalState } from "stores/types/store";
import { PlayButton } from "apps/spin-wheel/components";
import { kycPath, loginPath } from "utils/RequestHelper";

import { openUrl } from "../utils";
import styles from "./styles";
interface Props {
  onPlay?(): void;
}

const ControlBoard: React.FC<Props> = ({ onPlay }) => {
  const { lucky_trade_spin_balance } = useSelector((state: GlobalState) => state.user);
  const { campaign_name, kyc } = useSelector((state: GlobalState) => state.campaign);
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);

  const btnTitle = React.useMemo(() => {
    if (!campaign_name) return Locale.translate("login");
    if (!kyc) return Locale.translate("kyc");
    if (!lucky_trade_spin_balance) return Locale.translate("more_spin");
    return Locale.translate("spin");
  }, [campaign_name, kyc, lucky_trade_spin_balance]);

  const description = React.useMemo(() => {
    if (!campaign_name) return Locale.translate("login_to_continue");
    if (!kyc) return Locale.translate("kyc_to_continue");
    if (!lucky_trade_spin_balance) return Locale.translate("out_of_spin_turn");
    return (
      <SText color={COLORS.purplel800}>
        {Locale.translate("you_have")}
        <SText bold color={COLORS.yellow500}>
          {" "}
          {String(lucky_trade_spin_balance).padStart(2, "0")}
        </SText>{" "}
        {Locale.translate("spin_turn")}
      </SText>
    );
  }, [campaign_name, kyc, lucky_trade_spin_balance]);

  const onPress = React.useCallback(() => {
    if (!campaign_name) {
      return openUrl(isRemiBrowser, loginPath);
    }
    if(!kyc) {
      return openUrl(isRemiBrowser, kycPath);
    }

    onPlay && onPlay();
  }, [campaign_name, isRemiBrowser, kyc, onPlay]);

  return (
    <View style={styles.container}>
      <Image source={{ uri: CacheImageService.getCachedImg("board") }} style={styles.boardBg} />
      <View style={styles.contentContainer}>
        <View style={styles.playButtonBox}>
          <PlayButton title={btnTitle} onPress={onPress} />
        </View>
        <View style={styles.footerTitle}>
          <SText color={COLORS.purplel800}>{description}</SText>
        </View>
      </View>
    </View>
  );
};

export default ControlBoard;
