import { SText, SvgIcon } from "base-components";
import { ButtonWithBg } from "../../../../components";
import Locale from "locale";
import React from "react";
import { View } from "react-native";
import styles from "./styles";
import { ILuckyTradeSpinReward } from "services/LuckyDrawService";
import moment from "moment";
import { getRewardAction, getRewardIcon, getRewardName } from "apps/spin-wheel/screens/Home/components/utils";

interface Props {
  item: ILuckyTradeSpinReward;
  onPress(url: string): void;
  withTime?: boolean;
}

const ValidGiftItem: React.FC<Props> = ({ item, onPress, withTime }) => {
  if (!item) return null;
  const disabled = item.status === "used";
  const format = withTime ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  const expiredDate = item.expiry_timestamp ? moment(item.expiry_timestamp * 1000).format(format) : "N/A";
  const giftIcon = getRewardIcon(item.lucky_trade_gift.gift_type, item?.reward_params?.currency);
  const giftName = getRewardName(item.lucky_trade_gift, item?.reward_params);
  const { label, url } = getRewardAction(item.lucky_trade_gift.gift_type, item.status);
  return (
    <View style={styles.container}>
      <View style={[styles.coinLogo, disabled && styles.transperant50]}>
        <SvgIcon name={String(giftIcon)} size={52} />
      </View>
      <View style={[styles.giftContent, disabled && styles.transperant50]}>
        <SText bold size={15} style={styles.giftTitle}>
          {giftName}
        </SText>
        <SText style={styles.giftDescription}>{`${Locale.translate("expire")}: ${expiredDate}`}</SText>
      </View>
      <ButtonWithBg title={Locale.translate(label)} width={89} height={28} onPress={() => onPress(url)} />
    </View>
  );
};

export default ValidGiftItem;
