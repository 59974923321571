import React from "react";
import { ActivityIndicator, FlatList } from "react-native";
import LuckyDrawService, { IGiftType, ILuckyTradeSpinReward } from "services/LuckyDrawService";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeviceAction } from "stores/actions";
import Locale from "locale";
import { getRewardIcon, getRewardName, openUrl } from "apps/spin-wheel/screens/Home/components/utils";
import AlertHelper from "utils/AlertHelper";
import { GlobalState } from "stores/types/store";
import moment from "moment";
import ValidGiftItem from "../ValidGiftItem";
import styles from "./styles";
import { rewardCenterPath } from "utils/RequestHelper";

interface Props {
  foo?: string;
}

const ValidGiftList = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [rewards, setRewards] = React.useState<ILuckyTradeSpinReward[]>([]);
  const [nextPage, setNextPage] = React.useState<number | undefined>(0);
  const { lucky_trade_spin_balance } = useSelector((state: GlobalState) => state.user);
  const { campaign_name } = useSelector((state: GlobalState) => state.campaign);
  const { isRemiBrowser } = useSelector((state: GlobalState) => state.device);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    Promise.all([
      getSpinTurn(),
      fetchData({}),
    ]).finally(() => {
      setLoading(false);
    });
  }, [campaign_name]);

  const getSpinTurn = async () => {
    if (!campaign_name) return;
    await LuckyDrawService.getSpinTurn();
  };

  const currentSpinTurn: any = {
    reward_params: { amount: lucky_trade_spin_balance },
    expiry_timestamp: moment().utc().endOf("isoWeek").valueOf() / 1000,
    lucky_trade_gift: { gift_name: "Spin turn", gift_type: IGiftType.spin },
  };

  const fetchData = React.useCallback(
    async ({ page }: { page?: number }) => {
      if (!campaign_name) return;
      const res = await LuckyDrawService.getSpinRewards({ page, available: true });
      if (res?.lucky_trade_spin_rewards) {
        let newData = !page ? [] : rewards;
        newData = newData.concat(res?.lucky_trade_spin_rewards);
        setRewards(newData);
      }
      if (res?.meta) {
        setNextPage(res?.meta?.next_page);
      }
    },
    [campaign_name, rewards]
  );

  const onEndReached = React.useCallback(() => {
    if (nextPage) fetchData({ page: nextPage });
  }, [fetchData, nextPage]);

  const onItemAction = React.useCallback(
    async (url: string, item: ILuckyTradeSpinReward) => {
      try {
        if (!url) return;
        if (url === "use") {
          const res = await LuckyDrawService.useSpinReward({ ref: item.ref });
          if (res) {
            const rewardName = getRewardName(item.lucky_trade_gift, item.reward_params);
            const rewardIcon = getRewardIcon(
              item.lucky_trade_gift.gift_type,
              item.reward_params?.currency
            );
            dispatch({
              type: DeviceAction.SHOW_CUSTOM_ALERT,
              data: {
                title: rewardName,
                message: Locale.translate("use_gift_success"),
                icon: rewardIcon,
                type: "success",
              },
            });
            await fetchData({});
            setTimeout(() => {
              if (item.lucky_trade_gift.gift_type === IGiftType.voucher) {
                openUrl(isRemiBrowser, rewardCenterPath);
              }
            }, 200);
            return;
          }
        }
        if (url.startsWith("/")) return navigate(url, { replace: true });
        openUrl(isRemiBrowser, url);
      } catch (error) {
        AlertHelper.showError(error);
      }
    },
    [dispatch]
  );

  const renderItem = ({ item }: { item: ILuckyTradeSpinReward }) => {
    return <ValidGiftItem item={item} onPress={(url) => onItemAction(url, item)} />;
  };

  const renderHeader = () => {
    if (lucky_trade_spin_balance === 0) return null;
    return (
      <ValidGiftItem
        item={currentSpinTurn}
        onPress={(url) => onItemAction(url, currentSpinTurn)}
        withTime
      />
    );
  };

  if (loading) return <ActivityIndicator />;

  return (
    <FlatList
      data={rewards}
      style={styles.flex1}
      keyExtractor={(_, index) => `valid_gift_${index}`}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={1}
      showsVerticalScrollIndicator={false}
      ListHeaderComponent={renderHeader}
    />
  );
};

export default ValidGiftList;
