import React from "react";
import { View } from "react-native";
import UserRankItem from "../UserRankItem";
import { InView } from "react-native-intersection-observer";
import styles from "./styles";
import { GlobalState } from "stores/types/store";
import { useSelector } from "react-redux";
import { Ranking } from "stores/types/ranking";
import { Moment } from "moment";

interface Props {
  handleShowCurrentUser: (inView: boolean) => void;
  currentDate: Moment;
}

const UserRanks: React.FC<Props> = ({ handleShowCurrentUser, currentDate }) => {
  const { ranking } = useSelector((state: GlobalState) => state.ranking);
  const currentWeek = currentDate.isoWeek();

  return (
    <View style={styles.container}>
      {ranking[currentWeek]?.map((item: Ranking, index: number) =>
        item.isLocal ? (
          <InView
            key={item.rating}
            onChange={(inView: boolean) => {
              handleShowCurrentUser(inView);
            }}
          >
            <UserRankItem rankInfo={item} index={index} currentDate={currentDate} />
          </InView>
        ) : (
          <UserRankItem rankInfo={item} key={item.rating} currentDate={currentDate} />
        )
      )}
    </View>
  );
};

export default UserRanks;
