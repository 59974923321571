import React from "react";
import SText from "../SText";
import { View, Pressable, TextStyle } from "react-native";
import { animated, useSpring } from "@react-spring/native";
import styles from "./styles";

const AnimatedView = animated(View);

interface Props {
  activeTab?: number;
  onSelect?: (index: number) => void;
  tabs?: string[];
  textStyle?: TextStyle | TextStyle[];
}

const TabBarAnimated: React.FC<Props> = ({
  onSelect,
  tabs = ["long text option one", "Two", "3", "4", "5"],
  activeTab,
  textStyle = {},
}) => {
  const [focusTab, setFocusTab] = React.useState(activeTab || 0);
  const [itemFocusWidth, setItemFocusWidth] = React.useState(0);

  const itemFocusLeft = React.useMemo(
    () => itemFocusWidth * focusTab,
    [focusTab, itemFocusWidth]
  );

  const fade = useSpring({ left: itemFocusLeft });

  const handleSelect = React.useCallback((index: number) => {
    setFocusTab(index);
    onSelect?.(index);
  }, []);

  return (
    <View style={styles.container}>
      <AnimatedView
        style={[
          styles.itemFocus,
          {
            width: itemFocusWidth,
          },
          fade,
        ]}
      >
        <View style={styles.itemFocusContent} />
      </AnimatedView>
      {tabs.map((option, key) => (
        <Pressable
          onLayout={(event) => {
            if (key === 0) {
              const { width } = event.nativeEvent.layout;
              setItemFocusWidth(width);
            }
          }}
          key={key}
          onPress={() => handleSelect(key)}
          style={styles.item}
        >
          <SText numberOfLines={1} bold size={16} style={{...textStyle, ...styles.title}}>
            {option}
          </SText>
        </Pressable>
      ))}
    </View>
  );
};

export default TabBarAnimated;
