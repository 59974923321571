import React, { useMemo } from "react";
import {
  View,
  Modal,
  Pressable,
  TouchableOpacity,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Locale from "locale";
import Lottie from "lottie-react";

import { SText, SvgIcon, AuraSpin } from "base-components";
import SW_SCREENS from "apps/spin-wheel/screens/info";
import { GlobalState } from "stores/types/store";
import { Board, ButtonWithBg } from "apps/spin-wheel/components";
import RemitanoBrowser from "utils/RemitanoBrowser";
import { sendEventLog } from "utils/EventLogger";
import animationConfettifrom from "assets/animation-confetti.json";

import styles from "./styles";
import { getRewardIcon, getRewardName, shareFacebook } from "../utils";
import VarHelper from "utils/VarHelper";

export interface ModalRef {
  showReward(): void;
}

const RewardModal = React.forwardRef<ModalRef, any>(
  ({ reward, isCampaignTime, enableZaCampaign, onScrollToMission }, ref) => {
    const [isShowReward, setShowReward] = React.useState(false);
    const { isRemiBrowser, isRemiLiteBrowser } = useSelector((state: GlobalState) => state.device);
    const navigate = useNavigate();

    React.useImperativeHandle(ref, () => ({
      showReward: () => {
        setShowReward(true);
      },
    }));

    const icon = React.useMemo(
      () =>
        getRewardIcon(
          reward.lucky_trade_gift.gift_type,
          reward.reward_params.currency
        ) || "",
      [reward]
    );

    const rewardName = React.useMemo(
      () => getRewardName(reward.lucky_trade_gift, reward.reward_params),
      [reward]
    );

    const rewardHint = React.useMemo(() => {
      if (reward?.lucky_trade_gift?.gift_type === "good_luck") {
        const hintNumber = VarHelper.randomInRange(1, 3);
        return {
          hint: Locale.translate(`goodluck_hint_${hintNumber}`),
          description: Locale.translate(`goodluck_des_${hintNumber}`),
        };
      } else {
        const hintNumber = VarHelper.randomInRange(1, 2);
        return {
          hint: Locale.translate(`hint_${hintNumber}`),
          description: Locale.translate(`description_${hintNumber}`),
        };
      }
    }, [reward]);

    const onClose = () => setShowReward(false);

    const handleCheckReward = () => {
      if (enableZaCampaign) {
        setShowReward(false);
        onScrollToMission();
        return;
      }

      navigate(SW_SCREENS.MyRewardScreen.path);
    };

    const onPressShare = async () => {
      if (enableZaCampaign) {
        navigate(SW_SCREENS.MyRewardScreen.path);
        return;
      }
      
      sendEventLog("click_share_luckydraw", { button_place: "popup" });
      if (isRemiBrowser && !isRemiLiteBrowser) return RemitanoBrowser.shareScreen();
      await shareFacebook();
    };

    const checkRewardBtn = useMemo(() => {
      if (enableZaCampaign) return "Earn more spins";
      return Locale.translate("check_reward");
    }, [enableZaCampaign]);

    
    const shareRewardBtn = useMemo(() => {
      if (enableZaCampaign) return "Check rewards";
      return Locale.translate("share_reward");
    }, [enableZaCampaign]);

    return (
      <Modal visible={isShowReward} transparent>
        <TouchableOpacity style={styles.container} onPress={onClose}>
          <ScrollView>
            <View style={styles.rewardContent}>
              <AuraSpin size={200} />
              <Board
                title={Locale.translate("congratulation")}
                style={styles.boardContainer}
              >
                <TouchableWithoutFeedback>
                  <View style={styles.contentContainer}>
                    <TouchableOpacity
                      onPress={onClose}
                      style={styles.closeOutSite}
                    />
                    <View style={styles.lottie}>
                      <Lottie animationData={animationConfettifrom} />
                    </View>
                    <>
                      <SvgIcon name={icon} size={100} />
                      <View style={styles.rewardBox}>
                        <SText bold style={styles.title}>
                          {rewardHint.hint}
                        </SText>
                        <SText style={styles.reward}>{rewardName}</SText>
                        <SText style={styles.rewardDescription}>
                          {rewardHint.description}
                        </SText>
                      </View>
                      <ButtonWithBg
                        onPress={handleCheckReward}
                        title={checkRewardBtn}
                        width={251}
                        height={46}
                        style={styles.checkRewardBtn}
                        titleStyle={styles.checkRewardTitle}
                      />
                      <TouchableOpacity
                        style={styles.shareRewardBtn}
                        onPress={onPressShare}
                      >
                        <SText style={styles.titleShareBtn}>
                          {shareRewardBtn}
                        </SText>
                      </TouchableOpacity>
                    </>
                  </View>
                </TouchableWithoutFeedback>
              </Board>
              <Pressable style={styles.close} onPress={onClose}>
                <SvgIcon name="ic_close" size={32} />
              </Pressable>
            </View>
          </ScrollView>
        </TouchableOpacity>
      </Modal>
    );
  }
);

RewardModal.displayName = "RewardModal";

export default RewardModal;
