import React from "react";
import { ImageBackground, View, ViewStyle } from "react-native";
import CacheImageService from "services/CacheImageService";
import styles from "./styles";
import "./style.css";
import { SText } from "base-components";

interface Props {
  title?: string | null;
  children?: any;
  style?: ViewStyle;
}

const Board = (props: Props) => {
  const { title, children, style } = props;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.hr} />
      <div className="board-container">
        {title && (
          <ImageBackground
            source={{ uri: CacheImageService.getCachedImg("table-header") }}
            style={styles.header}
          >
            <SText style={styles.title} bold>
              {title}
            </SText>
          </ImageBackground>
        )}
        {children}
      </div>
    </View>
  );
};

export default Board;
