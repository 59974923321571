import React from "react";
import { Image, ScrollView, View } from "react-native";
import { NavBar, SText } from "base-components";
import Locale from "locale";
import { Board } from "apps/spin-wheel/components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import SW_SCREENS from "../info";
import styles, { RNStyle } from "./styles";

const GameRuleScreen = () => {
  const { campaign_content: gameRules } = useSelector((state: GlobalState) => state.campaign);
  const navigate = useNavigate();

  const onBack = () => navigate(SW_SCREENS.HomeScreen.path);
  return (
    <View style={styles.container}>
      <NavBar title={Locale.translate("game_rule")} onBack={onBack} />
      <View style={styles.flex1}>
        <ScrollView style={styles.flex1}>
          <View>
            <Image
              resizeMode="contain"
              source={require(`assets/spin-wheel/game-rule-banner-${Locale.currLocale}.png`)}
              style={[styles.banner, RNStyle.bannerHW]}
            />
            {(gameRules?.[Locale.currLocale] || []).map((rule, key) => {
              return (
                <Board key={key} title={key === 0 ? Locale.translate("game_rule") : null} style={styles.board}>
                  <View style={key === 0 ? styles.boardContent : styles.boardContentWithoutTitle}>
                    <SText size={16} bold style={styles.title}>
                      {rule.title}
                    </SText>
                    <div
                      style={styles.boardText}
                      dangerouslySetInnerHTML={{
                        __html: rule.description,
                      }}
                    />
                  </View>
                </Board>
              );
            })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default GameRuleScreen;
