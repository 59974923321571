import { COLORS as defaultColor } from "@remitano/remitano-ui-kit";

const COLORS = {
  ...defaultColor,
  MAIN: "grey",
  black: "#000000",
  vividOrange: "#E47201",
  yellow500: "#FFAB00",
  purplel800: "#EBDEF0",
  innerBorder: "#7D7189",
  shadowBoxBorder: "#7A18A3",
  shadowButton: "#919191",
  shadowButtonBorder: "#6D6376",
  shadowButtonDarkBorder: "#524261",
  mulledWine: "#524261",
  darkGreenBlue: "#29585e",
  solidPink: "#7b3042",
  brightGrey: "#3d2a4e",
  black1000: "#111111",
  boxBorder: "#9521C9",
};

export default COLORS;
