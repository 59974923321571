import React from "react";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import DeviceInfo from "utils/DeviceInfo";
import { View, StyleSheet, ImageBackground } from "react-native";
import { HomeScreen, SplashScreen, MyRewardsScreen, LeaderboardScreen, GameRulesScreen } from "./screens";
import SCREENS from "./screens/info";
import { AlertModal } from "./components";
import RequireAuth from "base-components/RequireAuth";
import { initFacebookSdk, initRemiBrowser } from "./screens/Home/components/utils";
import CacheImageService from "services/CacheImageService";

const MainApp = React.memo(() => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (loaded) {
      initFacebookSdk();
      initRemiBrowser();
    }
  }, [loaded]);

  if (!loaded) return <SplashScreen onReady={() => setLoaded(true)} />;
  return (
    <ImageBackground
      source={{ uri: CacheImageService.getCachedImg("star-background") }}
      style={styles.flex1}
    >
      <Router>
        <Routes>
          <Route
            path={SCREENS.HomeScreen.path}
            element={
              <RequireAuth>
                <HomeScreen />
              </RequireAuth>
            }
          />
          <Route
            path={SCREENS.MyRewardScreen.path}
            element={
              <RequireAuth>
                <MyRewardsScreen />
              </RequireAuth>
            }
          />
          <Route
            path={SCREENS.LeaderboardScreen.path}
            element={
              <RequireAuth>
                <LeaderboardScreen />
              </RequireAuth>
            }
          />
          <Route
            path={SCREENS.GameRulesScreen.path}
            element={
              <RequireAuth>
                <GameRulesScreen />
              </RequireAuth>
            }
          />
          <Route 
            path="*"
            element={<Navigate to={SCREENS.HomeScreen.path} replace />}
          />
        </Routes>
      </Router>
    </ImageBackground>
  );
});

const Navigation: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <MainApp />
        <AlertModal />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    alignItems: "center",
  },
  contentContainer: {
    width: DeviceInfo.CONTENT_WIDTH,
    height: "100%",
  },
  flex1: {
    flex: 1,
  },
});

export default React.memo(Navigation);
