import React, { useEffect, useState } from "react";
import { Image, View, ViewStyle } from "react-native";
import Ticker from "react-ticker";
import Locale from "locale";
import { SText } from "base-components";
import { RenderWithReact17 } from "render-with-react17/dist/src/RenderWithReact17";
import COLORS from "utils/CommonColors";
import LuckyDrawService from "services/LuckyDrawService";
import { getRewardName } from "apps/spin-wheel/screens/Home/components/utils";
import styles from "./styles";

interface Props {
  style?: ViewStyle;
}

const GetListFromAPI = () => {
  const [list, setList] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const nowTimeStamp = Math.floor(Date.now() / 1000);
      const ratesFromAPI = await LuckyDrawService.getJustWon(nowTimeStamp);
      setList(ratesFromAPI);
    }
    fetchData();
  }, []);

  return (
    <SText style={styles.runningText} size={12}>
      {list.map((item, index) => (
        <SText key={index} style={styles.runningText} size={12}>
          [{item["username"]}] {Locale.translate("just_won")}
          <SText color={COLORS.yellow500}>
            {" "}
            {getRewardName(
              { gift_type: item["gift_type"], gift_name: item["gift_name"] },
              { amount: item["amount"], currency: item["currency"] },
              false
            )}
          </SText>
        </SText>
      ))}
    </SText>
  );
};


const RunningNoti: React.FC<Props> = (props) => {
  return (
    <View style={[styles.container, props.style]}>
      <Image
        source={require("assets/spin-wheel/speaker.png")}
        style={styles.speakerIcon}
      />
      <View style={styles.tickerContainer}>
        <RenderWithReact17>
          <Ticker offset="run-in" speed={10}>
            {() => <GetListFromAPI />}
          </Ticker>
        </RenderWithReact17>
      </View>
    </View>
  );
};

export default React.memo(RunningNoti);
