import { CURRENT_MARKET } from "apps/spin-wheel/common";
import Store from "stores";
import { UserAction } from "stores/actions";
// import AlertHelper from "utils/AlertHelper";
import RequestHelper from "utils/RequestHelper";

class UserService {
  getCurrentUser = async () => {
    try {
      const res = await RequestHelper.get("/api/v1/users/me");
      if (res?.country_code) {
        localStorage.setItem(CURRENT_MARKET, res.country_code);
      }
      Store.dispatch({
        type: UserAction.UPDATE_USER_INFO,
        data: res,
      });
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
    }
  };
}

export default new UserService();
