import React from "react";
import { ActivityIndicator, View, Share } from "react-native";
import Locale from "locale";
import { Board } from "apps/spin-wheel/components";
import { isArray } from "lodash";
import styles from "./styles";
import MissionItem from "../MissionItem";
import LuckyDrawService, {
  ILuckyTradeMission,
  MissionType,
} from "services/LuckyDrawService";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import RemitanoBrowser from "utils/RemitanoBrowser";
import {
  kycPath,
  loginPath,
  swapPath,
  walletsPath,
  COINLYMPIA_DOMAIN,
  IMOTA_DOMAIN,
  COIN_SAVI_FANPAGE,
  COIN_SAVI_DOMAIN,
  LOYALTY_MISSION_DOMAIN,
  zaP2pPath,
  quickBuySellPath,
} from "utils/RequestHelper";
import { sendEventLog } from "utils/EventLogger";
import { openUrl, redirectUrl } from "../utils";
import { shareFacebook } from "../utils";
import { UserAction } from "stores/actions";

export interface MissionBoardRef {
  getPositionMission(): void;
  getMissions(): void;
}

const MissionBoard = React.forwardRef<MissionBoardRef, any>((props, ref) => {
  const [missionBoardOffset, setMissionBoardOffset] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);
  const [canLoadMore, setCanLoadMore] = React.useState(true);
  const [missions, setMissions] = React.useState<ILuckyTradeMission[]>([]);
  const [nextPage, setNextPage] = React.useState<number | undefined>(1);
  const { isRemiBrowser, isRemiLiteBrowser } = useSelector((state: GlobalState) => state.device);
  const { kyc } = useSelector((state: GlobalState) => state.campaign);
  const { lucky_trade_spin_balance } = useSelector(
    (state: GlobalState) => state.user
  );

  const dispatch = useDispatch();

  React.useImperativeHandle(ref, () => ({
    getPositionMission: () => {
      return missionBoardOffset?.y || 0;
    },
    getMissions: async () => {
      return await onFetchData();
    },
  }));

  React.useEffect(() => {
    onFetchData();
  }, []);

  React.useEffect(() => {
    const dailyAttendance = async () => {
      const res = await LuckyDrawService.completeMission("login");
      if (res) {
        fetchData({});
        LuckyDrawService.getSpinTurn();
      }
    };
    if (kyc) {
      dailyAttendance();
    }
  }, []);

  const fetchData = React.useCallback(
    async ({ page }: { page?: number }) => {
      if (!canLoadMore) return;
      try {
        setCanLoadMore(false);
        const res = await LuckyDrawService.getMissions({ page });
        if (res?.lucky_trade_missions && isArray(res?.lucky_trade_missions)) {
          let newData = !page ? [] : missions;
          newData = newData.concat(res?.lucky_trade_missions);
          setMissions(newData);
        }
        if (res?.meta) {
          setNextPage(res?.meta?.next_page);
        }
      } finally {
        setLoading(false);
        setCanLoadMore(true);
      }
    },
    [canLoadMore, missions]
  );

  const addSpinTurn = React.useCallback(
    (index: number) => {
      let spinAmount = 0;
      const newMission = missions.map((mission, key) => {
        if (key === index) {
          if (mission.completed_mission < mission.limit_amount) {
            mission.completed_mission += 1;
            spinAmount = mission.spin_amount;
          }
        }
        return mission;
      });
      setMissions(newMission);
      dispatch({
        type: UserAction.UPDATE_SPIN_TURN,
        data: lucky_trade_spin_balance + spinAmount,
      });
    },
    [dispatch, lucky_trade_spin_balance, missions]
  );

  const onComplete = React.useCallback(
    async (mission_type: MissionType, index: number) => {
      if (!kyc) {
        return openUrl(isRemiBrowser, kycPath);
      }
      let shared = false;
      switch (mission_type) {
        case "share":
          sendEventLog("click_share_luckydraw", { button_place: "mission" });
          if (isRemiBrowser && !isRemiLiteBrowser) {
            const res: any = await RemitanoBrowser.shareScreen();
            if (res.action === Share.sharedAction) {
              if (res.activityType) {
                if (res.activityType.includes("PostToFacebook")) {
                  shared = true;
                }
              } else {
                shared = true;
              }
            }
          } else {
            shared = await shareFacebook();
          }
          if (shared) {
            sendEventLog("share_done_luckydraw", { button_place: "mission" });
            await LuckyDrawService.completeMission("share");
            addSpinTurn(index);
          }
          break;
        case "swap":
        case "ng_trade":
          redirectUrl(isRemiBrowser, swapPath);
          break;
        case "quick_buy_sell":
          redirectUrl(isRemiBrowser, quickBuySellPath);
          break;
        case "fiat":
          redirectUrl(isRemiBrowser, walletsPath);
          break;
        case "quiz":
          window.location.href = COINLYMPIA_DOMAIN;
          break;
        case "lending":
          redirectUrl(isRemiBrowser, "/lending");
          break;
        case "login":
          openUrl(isRemiBrowser, loginPath);
          break;
        case "imota_wallet":
          openUrl(isRemiBrowser, IMOTA_DOMAIN);
          break;
        case "coin_to_zarr":
          openUrl(isRemiBrowser, zaP2pPath);
          break;
        case "coin_savi":
          openUrl(isRemiBrowser, COIN_SAVI_DOMAIN);
          break;
        case "collect_zarr_badges":
        case "collect_ng_badges":
        case "login_continuously":
        case "loyalty":
          openUrl(isRemiBrowser, LOYALTY_MISSION_DOMAIN);
          break;
        case "social_follow":
          openUrl(isRemiBrowser, COIN_SAVI_FANPAGE);
          await LuckyDrawService.completeMission("social_follow");
          addSpinTurn(index);
          break;
        default:
          break;
      }
    },
    [addSpinTurn, isRemiBrowser, kyc]
  );

  const onFetchData = React.useCallback(() => {
    if (nextPage) fetchData({ page: nextPage });
  }, [fetchData, nextPage]);

  return (
    <View onLayout={(event) => setMissionBoardOffset(event.nativeEvent.layout)}>
      <Board title={Locale.translate("mission_board")} style={styles.container}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          missions.map((mission, index) => {
            if (!mission || mission?.disabled) return null;
            return (
              <MissionItem
                key={`mission${index}`}
                item={mission}
                index={index}
                onComplete={onComplete}
                kyc={kyc}
              />
            );
          })
        )}
      </Board>
    </View>
  );
});

MissionBoard.displayName = "MissionBoard";

export default MissionBoard;
