import React from "react";
import { SText, SvgIcon } from "base-components";
import {
  View,
  TouchableOpacityProps,
  TouchableWithoutFeedback,
} from "react-native";
import { scale } from "utils/StyleSheet";
import styles from "./styles";

interface Props extends TouchableOpacityProps {
  width?: number;
  title?: string;
}

const PlayButton = (props: Props) => {
  const { width = 132, title, ...restProps } = props;
  const [isPress, setPress] = React.useState(false);

  return (
    <TouchableWithoutFeedback
      onPressIn={() => setPress(true)}
      onPressOut={() => setPress(false)}
      {...restProps}
    >
      <View key={`${title}`} style={styles.container}>
        <SvgIcon
          name={isPress ? "button_play_press" : "button_play"}
          width={width}
          height={52}
        />
        <SText
          style={[
            styles.title,
            {
              bottom: scale(isPress ? 13 : 18),
            },
          ]}
        >
          {title}
        </SText>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default PlayButton;
