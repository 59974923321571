import DeviceInfo from "utils/DeviceInfo";
import { StyleSheet } from "utils/StyleSheet";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    backdropFilter: "blur(10px)",
    marginTop: 48,
    width: 375,
    margin: "auto",
    height: DeviceInfo.HEIGHT,
  },
  title: {
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
    marginTop: 16,
  },
  contentContainer: {
    alignItems: "center",
    marginBottom: 24,
    marginTop: 6,
  },
  boardContainer: {
    marginHorizontal: 16,
  },
  rewardBox: {
    width: 242,
    textAlign: "center",
  },
  reward: {
    marginTop: 12,
    fontWeight: "900",
    fontSize: 24,
    lineHeight: 24,
  },
  rewardDescription: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 24,
  },
  checkRewardBtn: {
    marginTop: 24,
  },
  shareRewardBtn: {
    marginTop: 12,
    width: 247,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  titleShareBtn: {
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 16,
  },
  close: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  rewardContent: {
    paddingTop: 61,
  },
  checkRewardTitle: {
    fontSize: 18,
  },
  lottie: {
    position: "absolute",
    zIndex: 1,
    top: -100,
  },
  closeOutSite: {
    position: "absolute",
    zIndex: 2,
    height: 60,
    width: "100%",
    top: -100,
  },
});
export default styles;
