import { CampaignAction } from "../actions";
import { GenericAction } from "../types/actions";

const initialState = {
  campaign_content: {
    vi: [],
    en: [],
  },
  campaign_name: "",
  kyc: false,
  isCampaignTime: false,
  isZaCampaignTime: false,
  isVnCampaignTime: false,
};

const campaign = (state = initialState, action: GenericAction) => {
  switch (action.type) {
    case CampaignAction.UPDATE_CAMPAIGN:
      return {
        ...state,
        ...action.data,
      };
    case CampaignAction.UPDATE_CAMPAIGN_TIME:
      return {
        ...state,
        isCampaignTime: action.isCampaignTime,
        isZaCampaignTime: action.isZaCampaignTime,
        isVnCampaignTime: action.isVnCampaignTime,
      };
    default:
      return state;
  }
};

export default campaign;
