import React, { useMemo } from "react";
import { SText, SvgIcon } from "base-components";
import { TouchableOpacity, View } from "react-native";
import UserRankItem from "../UserRankItem";
import { animated, useSpring } from "@react-spring/native";
import styles from "./styles";
import { useSelector } from "react-redux";
import { GlobalState } from "stores/types/store";
import { Moment } from "moment";
import { getWeekInfo } from "apps/spin-wheel/screens/Home/components/utils";

const AnimatedView = animated(View);

export interface LeaderboardFooterRef {
  setInView(arg: boolean): void;
}

interface Props {
  onControl: (isNext: boolean) => void;
  currentDate: Moment;
  isNextDisabled?: boolean;
  isPrevDisabled?: boolean;
}

const LeaderboardFooter = React.forwardRef<LeaderboardFooterRef, Props>(({
  onControl,
  currentDate,
  isNextDisabled,
  isPrevDisabled
}, ref) => {
  const [inView, setInView] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { ranking, current_rank, current_balance } = useSelector((state: GlobalState) => state.ranking);
  const { username } = useSelector((state: GlobalState) => state.user);
  const currentWeek = currentDate.isoWeek();

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  React.useImperativeHandle(ref, () => ({
    setInView: (arg: boolean) => {
      setInView(arg);
    },
  }));

  const weekInfo = React.useMemo(() => getWeekInfo(currentDate), [currentDate]);

  const localUser = useMemo(() => {
    const local = ranking[currentWeek]?.find((item) => item.isLocal);
    if (local) {
      return local;
    }

    return {
      rating: current_rank,
      name: username || "",
      balance: current_balance,
      isLocal: true,
    };
  }, [ranking, currentWeek, current_rank, username, current_balance]);

  const fade = useSpring({
    opacity: !loading && !inView && localUser ? 1 : 0,
  });

  return (
    <View>
      {!loading && !inView && localUser && (
        <AnimatedView style={{ ...styles.bg, ...fade }}>
          <UserRankItem rankInfo={localUser} currentDate={currentDate} />
        </AnimatedView>
      )}
      <View style={styles.dateBox}>
        <TouchableOpacity onPress={() => onControl(false)} disabled={isPrevDisabled}>
          <SvgIcon name="prev" size={32} style={isPrevDisabled ? styles.overlay : {}}/>
        </TouchableOpacity>

        <SText size={18} bold style={styles.dateContent}>
          {weekInfo}
        </SText>
        <TouchableOpacity onPress={() => onControl(true)} disabled={isNextDisabled}>
          <SvgIcon name="next" size={32} style={isNextDisabled ? styles.overlay : {}}/>
        </TouchableOpacity>
      </View>
    </View>
  );
});

LeaderboardFooter.displayName = "LeaderboardFooter";

export default LeaderboardFooter;
